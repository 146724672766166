import { useState, useEffect } from 'react';
import {IonSelect, IonSelectOption, IonSpinner, IonButton, IonInput, IonItem, IonLabel, IonList, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import ObjectID from 'bson-objectid';

const Page: React.FC = () => {
  const [presentToast] = useIonToast()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(0)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [img, setImg] = useState('')
  const [deepLink, setDeepLink] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [gender, setGender] = useState('both')
  const [regions, setRegions] = useState([])
  const regionsList = [
    'Auvergne-Rhône-Alpes',
    'Bourgogne-Franche-Comté',
    'Bretagne',
    'Centre-Val de Loire',
    'Corse',
    'Grand Est',
    'Hauts-de-France',
    'Île-de-France',
    'Normandie',
    'Nouvelle-Aquitaine',
    'Occitanie',
    'Pays de la Loire',
    'Provence-Alpes-Côte d\'Azur'
  ]
  const [categories, setCategories] = useState([])
  const [categoriesList, setCategoriesList] = useState([])

  const fetchUsers = async () => {
    setLoading(true)
    const params = {}
    if (email.indexOf(';') === -1) {
      if (email) params['email'] = email
      if (firstname) params['first_name'] = firstname
      if (lastname) params['last_name'] = lastname
      if (gender && gender !== 'both') params['profile.gender'] = gender
      if (regions && regions.length > 0) params['profile.region'] = { $in: regions}
      if (categories && categories.length > 0) params['categories'] = { $in: categories}
    } else {
      const emails = email.split(';')
      params['email'] = { $in: emails }
    }
    const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/fetch_users_with_filters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        params
      })
    })
    const data = await response.json()
    setUsers(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchCategoriesList()
    fetchUsers()
  }, []); // eslint-disable-line

  const handleSend = async () => {
    if (!confirm(`Send to: ${users} users?`)) // eslint-disable-line
      return presentToast({
        message: 'Aborted',
        duration: 2000,
        color: 'danger'
      })
    setLoading(true)
    const params = {}
    if (email.indexOf(';') === -1) {
      if (email) params['email'] = email
      if (firstname) params['first_name'] = firstname
      if (lastname) params['last_name'] = lastname
      if (gender && gender !== 'both') params['profile.gender'] = gender
      if (regions && regions.length > 0) params['profile.region'] = { $in: regions}
      if (categories && categories.length > 0) params['categories'] = { $in: categories}
    } else {
      const emails = email.split(';')
      params['email'] = { $in: emails }
    }
    const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/send_push_notification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        params,
        title,
        content,
        img,
        deepLink
      })
    })
    const data = await response.json()
    if (data.error) {
      presentToast({
        message: data.error,
        duration: 2000,
        color: 'danger'
      })
    } else {
      presentToast({
        message: 'Push notifications envoyées',
        duration: 2000,
        color: 'success'
      })
    }
    setLoading(false)
  }

  const fetchCategoriesList = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/categories/used`)
    const categories = await response.json()
    categories.map((category: any) => {
      category._id = ObjectID(category._id)
      return category
    })
    setCategoriesList(categories)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Push Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Push Notifications</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem>
            <IonLabel>Email</IonLabel>
            <IonInput placeholder="alan@vogz.io" value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Prénom</IonLabel>
            <IonInput placeholder="Pierre" value={firstname} onIonChange={e => setFirstname(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Nom</IonLabel>
            <IonInput placeholder="Bourdague" value={lastname} onIonChange={e => setLastname(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Genre</IonLabel>
            <IonSelect interface="popover" value={gender} onIonChange={e => setGender(e.detail.value)} placeholder="Genre">
              <IonSelectOption value="both">Tous</IonSelectOption>
              <IonSelectOption value="male">Hommes</IonSelectOption>
              <IonSelectOption value="female">Femmes</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>Tags</IonLabel>
            <IonSelect interface="popover" value={categories} onIonChange={e => setCategories(e.detail.value)} placeholder="Tags" multiple={true}>
              {categoriesList.map((category: any) => (
                <IonSelectOption key={category._id} value={category._id}>{category.name}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>Régions</IonLabel>
            <IonSelect interface="popover" value={regions} onIonChange={e => setRegions(e.detail.value)} placeholder="Régions" multiple={true}>
              {regionsList.map((region: any) => (
                <IonSelectOption key={region} value={region}>{region}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonButton size='large' onClick={() => fetchUsers()}>Filtrer</IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>Titre</IonLabel>
            <IonInput placeholder="a vos vogz" value={title} onIonChange={e => setTitle(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Contenu</IonLabel>
            <IonInput placeholder="hey, clique ici pour ..." value={content} onIonChange={e => setContent(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Image</IonLabel>
            <IonInput placeholder="https://...." value={img} onIonChange={e => setImg(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Deeplink</IonLabel>
            <IonInput placeholder="vogz://...." value={deepLink} onIonChange={e => setDeepLink(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonButton onClick={() => handleSend()} disabled={loading || users === 0} size='large'>Envoyer à {loading ? <IonSpinner></IonSpinner> : users} Vogzters</IonButton>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Page;