import { useState, useEffect } from 'react';
import { IonCardContent, IonButton, IonGrid, IonCard, IonRow, IonCardHeader, IonBadge, IonCol, IonCardSubtitle, useIonToast, IonRefresher, IonRefresherContent, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import MuxPlayer from "@mux/mux-player-react";

const Page: React.FC = () => {
  const [presentToast] = useIonToast();
  const [userProfileVideos, setUserProfileVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleRefresh = (event: CustomEvent) => {
    fetchUsersProfileVideos();
    event.detail.complete();
  };

  const fetchUsersProfileVideos = async (load = false) => {
    load && setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/fetch_profile_media_to_validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey')
      })
    })
    const data = await response.json()

    setUserProfileVideos(data);
    load && setLoading(false);
  };

  useEffect(() => {
    fetchUsersProfileVideos(true)
  }, []); // eslint-disable-line

  const handleApprove = async (userID, videoID, type) => {
    await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/update_profile_media_status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        type: type,
        userId: userID,
        mediaId: videoID,
        status: 'approved'
      })
    })
    presentToast({
      message: 'Video validée',
      duration: 2000,
      color: 'success'
    });
    fetchUsersProfileVideos();
  };

  const handleReject = async (userID, videoID, type) => {
    await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/update_profile_media_status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        type: type,
        userId: userID,
        mediaId: videoID,
        status: 'rejected'
      })
    })
    presentToast({
      message: 'Video refusée',
      duration: 2000,
      color: 'danger'
    });
    fetchUsersProfileVideos();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Modération des videos profiles</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Modération des videos profiles</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading ? (
          <div className="ion-text-center ion-padding">
            <p>Loading...</p>
          </div>
          ) : (
          <IonGrid>
            <IonRow>
              <IonCard key="header">
                <IonCardHeader>
                  <IonBadge>{userProfileVideos.length} Vogzter{userProfileVideos.length > 1 && 's'}</IonBadge>
                </IonCardHeader>
              </IonCard>
            </IonRow>
            <IonRow>
            {userProfileVideos.map(user => (
                <IonCol key={user._id} size="12" size-sm="12" size-md="6" size-lg="4">
                  <IonCard key={user._id}>
                    <IonCardHeader>
                      <IonCardSubtitle>Vogzter: {user.first_name} {user.last_name}</IonCardSubtitle>
                    </IonCardHeader>
                    {user.profile.video_intro?.viewed === false && user.profile.video_intro.status === 'uploaded' && (
                      <>
                        <IonCardHeader>
                          <IonCardSubtitle>Video Intro</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                          <MuxPlayer
                            playbackId={user.profile.video_intro.source.substring(user.profile.video_intro.source.lastIndexOf('/') + 1).replace('.m3u8', '')}
                            autoPlay={false}
                            streamType='on-demand'
                          />
                        </IonCardContent>
                        <IonButton expand="block" size="large" color="success" onClick={() => handleApprove(user._id, user.profile.video_intro.external_id, 'intro')}>Valider</IonButton>
                        <IonButton expand="block" size="large" color="danger" onClick={() => handleReject(user._id, user.profile.video_intro.external_id, 'intro')}>Refuser</IonButton>
                      </>
                    )}
                    {user.profile.video_demo.length > 0 && user.profile.video_demo.map(video => (
                      <div key={video._id}>
                        {video.viewed === false && video.status === 'uploaded' && (
                          <>
                            <IonCardHeader>
                              <IonCardSubtitle>Video Demo</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <MuxPlayer
                                playbackId={video.source.substring(video.source.lastIndexOf('/') + 1).replace('.m3u8', '')}
                                autoPlay={false}
                              />
                            </IonCardContent>
                            <IonButton expand="block" size="large" color="success" onClick={() => handleApprove(user._id, video.external_id, 'demo')}>Valider</IonButton>
                            <IonButton expand="block" size="large" color="danger" onClick={() => handleReject(user._id, video.external_id, 'demo')}>Refuser</IonButton>
                          </>
                        )}
                      </div>
                    ))}
                  </IonCard>
                </IonCol>
            ))}
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Page;
