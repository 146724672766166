import { useState, useEffect } from 'react';
import { IonCardContent, IonButton, IonGrid, IonCard, IonRow, IonCardHeader, IonBadge, IonCol, IonCardSubtitle, useIonActionSheet, useIonToast, IonRefresher, IonRefresherContent, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import MuxPlayer from "@mux/mux-player-react";

const Page: React.FC = () => {
  const [present] = useIonActionSheet();
  const [presentToast] = useIonToast();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleRefresh = (event: CustomEvent) => {
    fetchVideos();
    event.detail.complete();
  };

  const fetchVideos = async (load = false) => {
    load && setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/fetch_media_to_validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey')
      })
    })
    const data = await response.json()

    setVideos(data);
    load && setLoading(false);
  };

  useEffect(() => {
    fetchVideos(true);
  }, []); // eslint-disable-line

  const declineReasons = [
    { text: 'Refus Général', value: 'declined' },
    { text: 'Règles', value: 'declined-rules' },
    { text: 'Qualité', value: 'declined-quality' },
    { text: 'Identité', value: 'declined-identity' },
    { text: 'Fraude', value: 'declined-fraud' },
    { text: 'Son', value: 'declined-sound' },
    { text: 'Hors Sujet', value: 'declined-relevance' }
  ];

  const handleApprove = async (videoID) => {
    await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/update_media_status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        mediaId: videoID,
        status: 'validated'
      })
    })
    presentToast({
      message: 'VOGZ validée',
      duration: 2000,
      color: 'success'
    });
    fetchVideos();
  };

  const handleReject = async (videoID, declinedReason) => {
    await fetch(`${process.env.REACT_APP_APP_URL}/api/mongo_rest/update_media_status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKey: localStorage.getItem('apiKey'),
        mediaId: videoID,
        status: 'declined',
        declinedReason: declinedReason
      })
    })
    presentToast({
      message: 'VOGZ refusée',
      duration: 2000,
      color: 'danger'
    });
    fetchVideos();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Modération des VOGZ</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Modération des VOGZ</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading ? (
          <div className="ion-text-center ion-padding">
            <p>Loading...</p>
          </div>
          ) : (
          <IonGrid>
            <IonRow>
              <IonCard key="header">
                <IonCardHeader>
                  <IonBadge>{videos.length} VOGZ</IonBadge>
                </IonCardHeader>
              </IonCard>
            </IonRow>
            <IonRow>
            {videos.map(video => (
              <IonCol key={video._id} size="12" size-sm="12" size-md="6" size-lg="4">
                <IonCard key={video._id}>
                  <IonCardHeader>
                    <IonCardSubtitle>Casting: {video?.brief[0]?.name}</IonCardSubtitle>
                    <IonCardSubtitle>Vogzter: {video?.user[0]?.first_name} {video.user[0].last_name}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <MuxPlayer
                      playbackId={video.source.substring(video.source.lastIndexOf('/') + 1).replace('.m3u8', '')}
                      autoPlay={false}
                      streamType='on-demand'
                    />
                  </IonCardContent>
                  <IonButton expand="block" size="large" color="success" onClick={() => handleApprove(video._id)}>Valider</IonButton>
                  <IonButton
                    expand="block"
                    size="large"
                    color="danger"
                    onClick={() =>
                      present({
                        header: 'Refuser',
                        subHeader: 'Raison du Refus',
                        buttons: [
                          ...declineReasons.map(reason => ({
                            text: reason.text,
                            role: 'destructive',
                            data: {
                              video: video._id,
                              reason: reason.value
                            }
                          })),
                          {
                            text: 'Annuler',
                            role: 'cancel'
                          }
                        ],
                        onDidDismiss: ({ detail }) => {
                          if (detail.data !== undefined)
                            handleReject(detail.data.video, detail.data.reason);
                        }
                      })
                    }
                  >
                    Refuser
                  </IonButton>
                </IonCard>
              </IonCol>
            ))}
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Page;
