import { useState, useEffect } from 'react';
import {IonRow, IonCol, IonImg, IonSpinner, IonButton, IonInput, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react';

const Page: React.FC = () => {
    const [presentToast] = useIonToast()
    const [loading, setLoading] = useState(false)
    const [mediaId, setMediaId] = useState('')
    const [masters, setMasters] = useState([])

    const generateMediaMaster = async () => {
        if (!mediaId || mediaId.length < 10) {
            presentToast({
                message: 'Media ID is invalid',
                duration: 2000,
                color: 'danger'
            })
            setLoading(false)
            return
        }
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/generate_media_master`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              apiKey: localStorage.getItem('apiKey'),
              mediaId: mediaId,
            })
        })
        const data = await response.json()
        if (data.error) {
            presentToast({
                message: data.error,
                duration: 2000,
                color: 'danger'
            })
        } else {
            presentToast({
                message: 'Master generation done',
                duration: 2000
            })
            getAllMasters()
        }
        setLoading(false)
    }

    const getAllMasters = async () => {
        const response = await fetch(`${process.env.REACT_APP_APP_URL}/api/get_media_masters`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                apiKey: localStorage.getItem('apiKey')
            })
        })
        const data = await response.json()
        setMasters(data.masters)
    }

    useEffect(() => {
        getAllMasters()
    },[])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Génération des masters</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Génération des masters</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonItem>
                    <IonLabel>Media ID</IonLabel>
                    <IonInput placeholder="64a6f4cf5e5b0d98f4ecb24c" value={mediaId} onIonChange={e => setMediaId(e.detail.value!)}></IonInput>
                </IonItem>
                <IonButton onClick={generateMediaMaster}>{loading ? <IonSpinner /> : 'Générer le master'}</IonButton>
                <IonRow>    
                    {masters.map((master: any) => (
                        <IonCol key={master.source} size="12" size-sm="12" size-md="6" size-lg="4">
                            <a href={master.source} target='_blank' rel="noreferrer"><IonImg style={{ height: '300px' }} src={master.thumbnail}></IonImg></a>
                        </IonCol>
                    ))}
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default Page;