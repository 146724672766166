import { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import VogzModerationPage from './pages/VogzModerationPage';
import PushNotificationPage from './pages/PushNotificationPage';
import UserVideoModerationPage from './pages/UserVideoModerationPage';
import MediaMasterGenerationPage from './pages/MediaMasterGenerationPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {

  useEffect(() => {
    if (localStorage.getItem('apiKey') === null) {
      let apiKeyInput = prompt("Veuillez saisir votre API Key", "")
      if (apiKeyInput == null) {
        alert("Vous devez saisir une API Key pour accéder à cette page");
        window.location.reload();
      } else {
        localStorage.setItem('apiKey', apiKeyInput);
      }
    }
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/page/moderation-vogz" />
            </Route>
            <Route path="/page/moderation-vogz" exact={true}>
              <VogzModerationPage />
            </Route>
            <Route path="/page/moderation-profile" exact={true}>
              <UserVideoModerationPage />
            </Route>
            <Route path="/page/push" exact={true}>
              <PushNotificationPage />
            </Route>
            <Route path="/page/media-master-generation" exact={true}>
              <MediaMasterGenerationPage />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
